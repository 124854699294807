#tracking[data-v-026e3240] {
    background-image: url(/images/background-tracking.png);
    color: #26303c;
    min-height: 85vh;
}

#tracking[data-v-026e3240],
#tracking .tracking-box[data-v-026e3240] {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

#tracking .tracking-box[data-v-026e3240] {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 20px rgb(38 48 60 / 15%);
    flex-direction: column;
    max-width: 815px;
    padding: 32px 0;
}

#tracking[data-v-026e3240],
#tracking .tracking-box[data-v-026e3240] {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

#tracking .tracking-box form[data-v-026e3240] {
    align-items: center;
    display: flex;
}


#tracking .tracking-box form button[data-v-026e3240] {
    background-color: #2bc866;
    border: 1px solid #2bc866;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 14px 30px;
    text-decoration: none;
    text-transform: uppercase;
    /* transition: background-color .2s, color .2s; */
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.tracking-redirect-container .tracking-redirect-wrapper {
    margin: 80px auto;
    max-width: 870px;
    overflow: hidden;
}

#tracking .tracking-box form input[data-v-026e3240] {
    background-color: #fff;
    border: none;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    font-size: 16px;
    margin: 0 -1px 0 0;
    padding: 14px;
    width: 315px;
}

#main-sponsor[data-v-037dfa7c] {
    box-shadow: 0 5px 20px rgb(38 48 60 / 15%);
    position: relative;
    width: 100%;
    z-index: 0;
}

#main-sponsor .holder nav[data-v-037dfa7c] {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 650px;
}

section {
    display: block;
    background-color: white;
}

#main-sponsor .holder p[data-v-037dfa7c] {
    color: #73818a;
}

.desktop-only[data-v-037dfa7c] {
    display: flex !important;
}

.desktop-only {
    display: flex !important;
}

.header-redirect .wrapper .info-holder p[data-v-372ecb4c] {
    font-size: 18px;
}

.header-redirect .wrapper .info-holder p[data-v-372ecb4c] {
    margin: 0;
}

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

:-webkit-any(article, aside, nav, section) h1 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3b3f51;
    font-family: Lato, Helvetica, Arial, sans-serif;
}

#main-sponsor .holder nav>a[data-v-037dfa7c] {
    cursor: default;
    width: 100%;
}

#main-sponsor .holder[data-v-037dfa7c] {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 925px;
    padding: 35px 0;
    width: 100%;
}
/* Progressive bar */
.progressive-container .wrapper .icon-progress[data-v-b28ba386] {
    display: block;
    height: 40px;
    position: relative;
    width: 40px;
    z-index: 3;
}

.progressive-container .wrapper.step-1.success-step .icon-progress[data-v-b28ba386] {
    background-image: url(/images/truck-green-progress.png);
}

.progressive-container .wrapper.step-1 .icon-progress[data-v-b28ba386] {
    background: url(/images/truck-gray-progress)0 0 no-repeat;
    background-size: contain;
}

/* Rastreio/Correios */
.tracking-redirect-wrapper h1 {
    font-size: 24px;
}

.tracking-redirect-container .tracking-redirect-wrapper {
    margin: 80px auto;
    max-width: 870px;
    overflow: hidden;
}

.progressive-container[data-v-b28ba386] {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 24px;
    max-width: 870px;
}

.progressive-container .wrapper[data-v-b28ba386] {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 86px;
    position: relative;
}

.progressive-container .wrapper.step-0.success-step .icon-progress[data-v-b28ba386] {
    background-image: url(/images/hand-green-progress.png);
}

.progressive-container .wrapper.step-0 .icon-progress[data-v-b28ba386] {
    background: url(/images/hand-green-progress.png) 0 0 no-repeat;
    background-size: contain;
}

.progressive-container .wrapper .content[data-v-b28ba386] {
    text-align: center;
}

.progressive-container .wrapper.current-step .content h5[data-v-b28ba386],
.progressive-container .wrapper.success-step .content h5[data-v-b28ba386] {
    color: #2bc866;
}

.progressive-container .wrapper .content h5[data-v-b28ba386] {
    color: #c8cdda;
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    display: block;
}

.progressive-container .wrapper.current-step .content span[data-v-b28ba386],
.progressive-container .wrapper.success-step .content span[data-v-b28ba386] {
    opacity: 1;
}

.progressive-container .wrapper .content span[data-v-b28ba386] {
    color: #3b3f51;
    font-size: 14px;
    opacity: 0;
}

.progressive-container .wrapper .content[data-v-b28ba386] {
    text-align: center;
}

.header-redirect[data-v-372ecb4c] {
    background-color: #fff;
    box-shadow: 0 5px 20px rgb(38 48 60 / 15%);
    padding: 20px 0;
}

.header-redirect .wrapper[data-v-372ecb4c] {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 870px;
}

.table-information header[data-v-312ece04] {
    border-bottom: 1px solid #f4f4f4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 15px 20px;
}

.table-information .body li[data-v-312ece04] {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 16px 20px;
}

.table-information .body li .wrapper[data-v-312ece04]:first-child {
    align-items: center;
    display: flex;
}

.table-information .body li .wrapper .icon-status[data-v-312ece04] {
    height: 38px;
    margin-right: 24px;
    max-width: 38px;
    -o-object-fit: contain;
    object-fit: contain;
    width: auto;
}

.table-information .body li[data-v-312ece04] {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 16px 20px;
}

.table-information .body li[data-v-312ece04]:nth-child(2n) {
    background-color: #f4f4f4;
}

.table-information .body li h5[data-v-312ece04] {
    font-size: 16px;
}

.table-information .body li h4[data-v-312ece04],
.table-information .body li h5[data-v-312ece04],
.table-information .body li p[data-v-312ece04] {
    font-weight: 400;
    margin: 0;
}
.header-redirect .wrapper .info-holder[data-v-372ecb4c] {
    text-align: right;
}

.header-redirect .wrapper .info-holder h1[data-v-372ecb4c] {
    font-size: 24px;
}

.header-redirect .wrapper .info-holder h1[data-v-372ecb4c],
.header-redirect .wrapper .info-holder p[data-v-372ecb4c] {
    margin: 0;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.tracking-redirect-wrapper h1 {
    font-size: 24px;
}

:-webkit-any(article, aside, nav, section) h1 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3b3f51;
    font-family: Lato, Helvetica, Arial, sans-serif;
}
.progressive-container .wrapper.success-step .line[data-v-b28ba386] {
    background-color: #2bc866;
}

.progressive-container .wrapper .content h5[data-v-b28ba386] {
    color: #c8cdda;
    font-size: 16px;
    font-weight: 800;
    margin: 0;
}


.progressive-container .wrapper .line[data-v-b28ba386] {
    background-color: #c8cdda;
    display: block;
    height: 2px;
    left: 115px;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    width: 200px;
    z-index: 2;
}

.progressive-container .wrapper.step-1.success-step .icon-progress[data-v-b28ba386] {
    background-image: url(/images/truck-green-progress.png);
}

.progressive-container .wrapper.step-1 .icon-progress[data-v-b28ba386] {
    background: url(/images/truck-gray-progress.png) 0 0 no-repeat;
    background-size: contain;
}

.progressive-container .wrapper.current-step .content h5[data-v-b28ba386],
.progressive-container .wrapper.success-step .content h5[data-v-b28ba386] {
    color: #2bc866;
}

.progressive-container .wrapper .icon-progress[data-v-b28ba386] {
    display: block;
    height: 40px;
    position: relative;
    width: 40px;
    z-index: 3;
}

.progressive-container .wrapper.step-2.success-step .icon-progress[data-v-b28ba386] {
    background-image: url(/images/package-green-progress.png);
}

.progressive-container .wrapper.step-2 .icon-progress[data-v-b28ba386] {
    background: url(/images/package-gray-progress.png) 0 0 no-repeat;
    background-size: contain;
}

.progressive-container .wrapper.step-3.success-step .icon-progress[data-v-b28ba386] {
    background-image: url(/images/happy-green-progress.png);
    padding: 10%;
}

.progressive-container .wrapper.step-3 .icon-progress[data-v-b28ba386] {
    background: url(/images/happy-gray-progress.png) 0 0 no-repeat;
    background-size: contain;
}

.progressive-container .wrapper.current-step .icon-progress[data-v-b28ba386] {
    height: 40px;
    width: 40px;
}

/* statusXmovimentacao */
.table-information header[data-v-312ece04] {
    border-bottom: 1px solid #f4f4f4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 15px 20px;
}

.table-information header[data-v-312ece04] {
    border-bottom: 1px solid #f4f4f4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 15px 20px;
}

.table-information header h3[data-v-312ece04] {
    font-size: 14px;
    font-weight: 800;
    margin: 0;
}
