@import "~jqvmap/dist/jqvmap";
.jqvmap-label
{
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  pointer-events:none;
}
.jqvmap-pin {
  pointer-events:none;
}
  .jqvmap-zoomin, .jqvmap-zoomout {
    position: absolute;
    left: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 50%;
    background: rgba(0,0,0,0.65);
    padding: 0;
    color: white;
    width: 20px;
    height: 20px;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
  }
  .jqvmap-pin {
    pointer-events:none;
  }
  .jqvmap-zoomin, .jqvmap-zoomout
  {
    position: absolute;
    left: 10px;
    border-radius: 50%;
    background: rgba(0,0,0,0.65);
    color: white;
    width: 20px;
    height: 20px;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
  }
  .jqvmap-zoomin
  {
    top: 10px;
  }
  .jqvmap-zoomout
  {
    top: 40px;
  }
  .jqvmap-region
  {
    cursor: pointer;
  }
  .jqvmap-ajax_response
  {
    width: 100%;
    height: 500px;
  }
