// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
//@import 'variables';
@import 'common';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Trumbowyg Editor
@import "~trumbowyg/dist/ui/trumbowyg.min";
// Morris Charts
@import "~morris-js-module/morris";
// Date Time Picker
@import "~jquery-datetimepicker/jquery.datetimepicker";
// Full Calendar
@import "~fullcalendar/main.css";
// Color Picker
@import "~bootstrap-colorpicker/dist/css/bootstrap-colorpicker";
// Range Slider
@import "~ion-rangeslider/css/ion.rangeSlider";
//JVQ Map
@import "components/jqvmap";
// Stepper
@import "components/stepper";
// Toast
@import "~toastr/toastr";
// Avatar Placeholder
@import "libs/letters";
//Date Tables
@import "~datatables.net-bs4/css/dataTables.bootstrap4";
//LobiList TaskTodo
@import "components/lobilist";
//Sweetalert2
@import '~sweetalert2/src/sweetalert2.scss';

@import "basic/rastreio";
@import "components/_forms";
@import "components/_forms-material";
@import "components/sidebarMenu";
@import "components/shortcodes";
@import "components/promotionsBar";
@import "components/services";
@import "components/sticky";
@import "components/knob";
@import "components/counter";
@import "components/bootstrap-tagsinput";
@import "components/select2";
@import "components/nav";
@import "components/control-sidebar";
@import "components/chat";
@import "components/todo";
@import "components/timeline";
@import "components/preloaders";

//FontAwesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
