.p-t-0 {
  padding-top: 0 !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}
.p-t-b-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-t-b-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-t-b-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-t-b-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-t-b-80 {
  padding: 80px 0 !important;
}

.p-t-b-100 {
  padding: 100px 0 !important;
}

.p-10 {
  padding: 10px !important;
}

.p-40 {
  padding: 40px !important;
}


.p-t-50 {
  padding-top: 50px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-150 {
  margin-top: 150px;
  @include tablet-and-phone {
    margin-top: 50px;

  }
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-t-b-50 {
  margin: 50px 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.b-b {
  border-bottom: 1px solid $brand-border !important;
}
.b-b-light {
  border-bottom: 1px solid rgba(255,255,255,0.1) !important;
}
.b-b-dashed{
  border-bottom: 1px dashed $brand-border !important;
}

@include desktop {
  .b-r {
    border-right: 1px solid $brand-border
  }
  .b-l {
    border-left: 1px solid $brand-border;
  }
  .b-r-o {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
  }
  .b-l-o {
    border-left: 1px solid rgba(0, 0, 0, 0.09);
  }
}

.b-t {
  border-top: 1px solid $brand-border2;
}
.b-t-b {
  border-top: 1px solid $brand-border2;
  border-bottom: 1px solid $brand-border2;
}
.border {
  border: 1px solid $brand-border2;
}

.section-text-white {
  @include section-text-color(#fff);
}

.no-p {
  padding: 0 !important;
}
.no-m {
  margin: 0 !important;
}
.no-b, .b-0, .no-b .mainnav, .no-b li, .no-b > td, .no-b th, .no-b-child > div{
  border: medium none !important;
}
.no-r, .r-0 {
  border-radius: 0 !important;
}
.separator {
  border-bottom: 1px solid #edf0f2;
}

.grid {
  img {
    border-radius: 0;
  }
}

.r-3 {
  border-radius: 3px !important;
}

.r-5 {
  border-radius: 5px !important;
}

.r-10 {
  border-radius: 10px !important;
}

.r-15 {
  border-radius: 15px !important;
}

.r-20 {
  border-radius: 20px !important;
}

.r-30 {
  border-radius: 30px !important;
}
.circle{
  border-radius: 50% !important;
}

.paper-card {
  background: #fff;
  padding: 40px;
  box-shadow: $shadow ;
}

//shadow helpers
.shadow {
  box-shadow: $shadow !important;
}
.shadow1 {
  box-shadow: $shadow1 !important;
}
.shadow2 {
  box-shadow: $shadow2 !important;
}
.no-shadow {
  box-shadow: none !important;
}

.text-big-bold {
  font-size: 62px;
  font-weight: 800;
  line-height: 62px;
}

.font-weight-lighter, .thin {
  font-weight: 100 !important;
}

.font-weight-bolder, .bolder {
  font-weight: 900 !important;
}

.l-s-1 {
  letter-spacing: .2rem;
}

.l-s-2 {
  letter-spacing: .4rem;
}

.l-s-3 {
  letter-spacing: .6rem;
}

.l-s-4 {
  letter-spacing: .8rem;
}

.l-s-5 {
  letter-spacing: 1rem;
}

.s-12 {
  @include font-size(12px)
}

.s-14 {
  @include font-size(14px)
}
.s-18 {
  @include font-size(18px)
}

.s-24 {
  @include font-size(24px)
}

.s-36 {
  @include font-size(36px)
}

.s-48 {
  @include font-size(48px)

}
.s-64-lg{
  @include desktop {
    @include font-size(64px)
  }
}
.s-64 {
    @include font-size(64px)
}

.s-128 {
  @include desktop {
    @include font-size(128px)
  }
}

.s-256 {
  @include font-size(256px);
  @include phone {
    @include font-size(128px)
  }
}

.img-40 {
 width: 40px;
  height: 40px;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}
.w-30px{
  width: 30px;
}
.w-40px{
  width: 40px;
}
.img-80,.w-80px {
  width: 80px;
}
.img-100,.w-100px {
  width: 100px;
}
.img-150,.w-150px {
  width: 150px;
}
.height-50 {
  height: 50px;
}

.height-90 {
  height: 90px;
}
.height-100 {
  height: 100px;
}
.hv-100{
  min-height: 100vh;
}
.height-full {
  background: #F3F5F8;
  min-height: 100vh;
}
.width-400 {
  max-width: 400px;
}

.width-250 {
  width: 250px !important;
}
.width-900 {
  max-width: 900px;
}
.img-border {
  padding: 5px;
  border: 1px solid $brand-border;
}

.text-white {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  strong,
  a,
  b,
  li,
  i {
    @include color(#fff)
  }
  @include color(#fff)
}

.text-blue {
  @include color(#4ea9e0)
}

.text-red {
  @include color(#e84c3d)
}

.text-green {
  @include color(#40d17d)
}

.text-purple {
  @include color(#9b58b5)
}

.text-black {
  @include color(#000)
}

.text-yellow {
  @include color(#f1c40f)
}

.white {
  background: $white !important;
}

.light {
  background-color: $brand-bg;
}

.light-2 {
  background: #F6FAFD;
}

.text-light-blue {
  color: $brand-primary !important;
}

.light-blue {
  background-color: $brand-primary !important;
}

.dark-blue {
  background-color: $color-11 !important;
}

.blue4 {
  background-color: #274da8 !important;
}

.blue3 {
  background-color: #375EBC !important;
}

.blue2 {
  background-color: #4CA5E8 !important;
}
.blue1 {
  background-color: #78BCEE !important;
}

.success {
  background-color: #68ba3c !important;
}
.strawberry, .danger {
  background-color: $brand-danger !important;
}
.sunfollower, warning {
  background-color: $brand-warning !important;
}
.grey-black {
  background-color: #454545 !important;
}
.dark-grey {
  background-color: #2B2D34 !important;
}
.paper-white {
  background: #f5f8fa;
}
.mint {
  background-color: #45CFC0 !important;
}
.gradient {
  border: none;
  background: #4c88ef;
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #4c88ef), color-stop(100%, #17e4d9));
  background: -webkit-linear-gradient(-45deg, #4c88ef 0%, #17e4d9 100%);
  background: -webkit-linear-gradient(315deg, #4c88ef 0%, #17e4d9 100%);
  background: linear-gradient(135deg, #4c88ef 0%, #17e4d9 100%);
}

.gradient-green {
  background-image: linear-gradient(45deg, #00E3AE 0%, #9BE15D 100%);
}

.center {
  margin: 0 auto;
}

.border-list {
  li {
    border-bottom: 1px solid #eee;
    &:last-child {
      border: none;
    }
  }
}

@include desktop {
  .big-heading {
    font-size: 72px;
  }
  .pull-up-lg
  {
    margin-top: -120px;
  }
}

.parallel {
  overflow-x: hidden;
  width: 100%;
  @include desktop {
    overflow: hidden;
    position: fixed;
  }
}
.overlay-light::before {
  content: '';
  background: rgba(255, 255, 255, 0.78);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-light::before {
  content: '';
  background: rgba(255, 255, 255, 0.78);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-light::before {
  content: '';
  background: rgba(255, 255, 255, 0.78);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark::before {
  content: '';
  background: rgba(0, 0, 0, 0.78);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.section {
  position: relative;
}

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;;
}
.fixed {
  position: fixed !important;
}

[data-bg-repeat='false'] {
  background-repeat: no-repeat !important;
}

[data-bg-possition='bottom'] {
  background-position: bottom !important;
}

[data-bg-possition='top'] {
  background-position: top !important;
}

[data-bg-possition='center'] {
  background-position: center !important;
}

[data-bg-possition='centerleft'] {
  background-position: center left !important;
}

.grid {
  [class*='col-'], figure {
    padding: 0;
    margin: 0;
  }
  @include desktop {
    &.grid-border {
      [class*='col-'] {
        border-right: 1px solid #eee;
        &:last-child {
          border-right: 0;
        }
      }
      &.row {
        &:nth-of-type(1) {
          border-top: none !important;
        }
        border-top: 1px solid #eee;
      }
    }
  }
}

ul.list-group {
  &.list-group-striped li {
    &:nth-of-type(even) {
      background: $brand-bg;
    }
  }
  &.list-group-hover li:hover {
    background: red;
  }
}